<template>
    <div class="orgChart">
        <div class="opers">
            <button class="flex alignCenter" @click="addChildren"><img src="@/assets/img/personal/addUser.png" style="width:20px;height:22px;margin-right:12px;" alt=""> 新增用户组</button>
        </div>
        <div class="tables flex ">
            <div class="tablesLeft">
                <el-tree style="width:300px;" @node-click="nodeclick" :highlight-current="true" lazy :load="loadNode" :props="treeDefaultProps"></el-tree>
            </div>
            <div class="tablesright">
                <div class="tablesright1">
                    <div class="tablesright1Title flex alignCenter">
                        <img src="@/assets/img/personal/xjbm.png" style="width:27px;height:27px;margin-right:16px;" alt=""> 下级部门
                    </div>
                    <button v-if="showAddBtn" @click="addChildrenBranch" style="padding:4px 10px;margin-left:30px;margin-top:25px;"><i class="el-icon-plus"></i>添加子部门</button>
                    <div class="scrollVessel">
                        <div class="vesselList flex alignCenter spacebetween c33 fontW" v-for="(im,ix) in rightShowList" :key="ix">
                            <div class="vesselListName">{{im.name}}</div>
                            <span @click="editNode(im)">编辑</span>
                        </div>
                    </div>
                </div>
                <div class="tablesright2">
                    <div class="tablesright1Title flex alignCenter">
                        <img src="@/assets/img/personal/bmry.png" style="width:27px;height:16px;margin-right:16px;" alt=""> 部门人员
                    </div>
                    <div class="flex flexWrap" style="margin-top:20px;">
                        <button @click="importMember = true" style="margin-left:30px;margin-top:25px;"><i class="el-icon-plus"></i>成员批量导入</button>
                        <button @click="addMember = true" style="margin-left:30px;margin-top:25px;"><i class="el-icon-plus"></i>添加成员</button>
                        <button @click="yaoqingchengyuan" style="margin-left:30px;margin-top:25px;"><i class="el-icon-plus"></i>邀请成员</button>
                        <button @click="handleBranch" style="margin-left:30px;margin-top:25px;">调整部门</button>
                        <button @click="handleDel" style="margin-left:30px;margin-top:25px;">批量删除</button>
                        <button v-show="handleTurn == true" @click="handleTurn = false;memberDel=[]" style="margin-left:30px;margin-top:25px;">取消</button>
                        <button v-show="handleTurn == true" @click="handleNext" style="margin-left:30px;margin-top:25px;">确定</button>
                    </div>
                    <table class="tablePersonal" cellspacing="0">
                        <tr style="height:55px;background: #fafafa;">
                            <th v-show="handleTurn">选择</th>
                            <th style="width:110px;">姓名</th>
                            <!-- width:130px; -->
                            <th style="">所属用户组</th>
                            <th style="">电话</th>
                            <th style="width:90px;">部门负责人</th>
                            <th style="width:130px;">时间</th>
                            <th style="">操作项</th>
                        </tr>
                        <tr v-for="(im,ix) in personListData[showtableData]" :key="ix">
                            <td v-show="handleTurn">
                                <div @click="addMemberDel(im)" class="nocurr cursorP marginAuto" :class="memberDel.includes(im.id) ? 'isCurr' : ''">
                                    <i class="el-icon-check"></i>
                                </div>
                            </td>
                            <td>{{im.name}}</td>
                            <td>{{im.pid_name}}</td>
                            <td>{{im.phone}}</td>
                            <td>{{im.is_respon == 1 ? '是' : '否'}}</td>
                            <td>{{im.ctime}}</td>
                            <td><span class="cursorP" @click="delMember(im)">删除</span></td>
                        </tr>
                    </table>
                    <el-pagination
                    style="margin-top:37px;"
                    background
                    layout="prev, pager, next"
                    :total="personListCount"
                    :page-size="5"
                    @current-change="currentChange">
                    </el-pagination>
                </div>
            </div>
        </div>

        <!-- 添加子部门弹窗 Start -->
        <el-dialog
        :title="centerDialogTitle | filt"
        :visible.sync="centerDialogVisible"
        width="600px"
        center>
        <div>
            <table v-show="showAddIndex == 1">
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属机构：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{user.name}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">组织名称：</td>
                    <td><el-input style="width:300px;" v-model="addOrg.name" placeholder=""></el-input></td>
                </tr>
            </table>
            <table v-show="showAddIndex == 2" v-if="tempAddDate">
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属机构：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{user.name}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属组织：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{tempAddDate.name | filt}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">部门名称：</td>
                    <td><el-input style="width:300px;" v-model="addOrg.name" placeholder=""></el-input></td>
                </tr>
            </table>
            <table v-show="showAddIndex == 3" v-if="tempAddDate">
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属机构：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{user.name}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属部门：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{tempAddDate.name | filt}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">科室名称：</td>
                    <td><el-input style="width:300px;" v-model="addOrg.name" placeholder=""></el-input></td>
                </tr>
            </table>
            <table v-show="showAddIndex == 4" v-if="tempAddDate">
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属机构：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{user.name}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">所属科室：</td>
                    <td style="text-align:left;font-size:15px;color:#212223">{{tempAddDate.name | filt}}</td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">小组名称：</td>
                    <td><el-input style="width:300px;" v-model="addOrg.name" placeholder=""></el-input></td>
                </tr>
            </table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="nextsetp">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 添加子部门弹窗 End -->
        <!-- 修改 机构、部门、科室、小组 信息（名称）Start -->
         <!-- 添加子部门弹窗 Start -->
        <el-dialog
        :title="'修改' + editTitle"
        :visible.sync="editTurn"
        width="600px"
        center>
        <table>
            <tr>
                <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">现用名称：</td>
                <td>{{new_next.oldname}}</td>
            </tr>
            <tr>
                <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">{{editTitle}}名称：</td>
                <td><el-input style="width:300px;" v-model="new_next.name" placeholder=""></el-input></td>
            </tr>
        </table>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editTurn = false">取 消</el-button>
            <el-button type="primary" @click="editNext">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 修改 机构、部门、科室、小组 信息（名称）End -->
        <!-- 添加成员 Start -->
        <el-dialog
        title="添加成员"
        :visible.sync="addMember"
        width="600px"
        center>
        <div>
            <table>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">姓名</td>
                    <td><el-input style="width:300px;" v-model="addMemberObj.name" placeholder=""></el-input></td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">手机&登录账号</td>
                    <td><el-input style="width:300px;" v-model="addMemberObj.phone" placeholder=""></el-input></td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">设置密码</td>
                    <td><el-input style="width:300px;" v-model="addMemberObj.password" placeholder=""></el-input></td>
                </tr>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">确认密码</td>
                    <td><el-input style="width:300px;" v-model="addMemberObj.confirm_password" placeholder=""></el-input></td>
                </tr>
            </table>
            <add-member @memberData='memberData'></add-member>
            <table>
                <tr>
                    <td style="width:130px;height:55px;text-align:right;padding-right:20px;font-size:15px;color:#212223">设为部门负责人</td>
                    <td>
                        <el-radio v-model="addMemberObj.is_respon" label="1">是</el-radio>
                        <el-radio v-model="addMemberObj.is_respon" label="0">否</el-radio>
                    </td>
                </tr>
            </table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addMember = false">取 消</el-button>
            <el-button type="primary" @click="addmemberNext">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 添加成员 End -->
        <!-- 调整部门 Start -->
        <el-dialog
        title="调整部门"
        :visible.sync="EditMember"
        width="600px"
        center>
        <div>
            <add-member @memberData='memberData'></add-member>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="EditMember = false">取 消</el-button>
            <el-button type="primary" @click="editmemberNext">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 调整部门 End -->
        <!-- 批量导入 Start -->
        <el-dialog
        title="成员批量导入"
        :visible.sync="importMember"
        width="600px"
        center>
        <div>
            <div class="marginAuto flex flexColumn alignCenter spacebetween" style="height:160px;">
            <uploadimg :impose="true" :upbase="'Organ/member_import'" @getcallback="getcallback">
                <div class="upcss flex spacearound" style="color:#999999">
                    <div class="borders flex alignCenter spacearound">
                        <i class="el-icon-plus cursorP" style="width:12px;height:12px;color:#dddddd;"></i>
                    </div>
                </div>
            </uploadimg>
            请选择要上传的excel文件
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="importMember = false;organMemberList()">关 闭</el-button>
        </span>
        </el-dialog>
        <!-- 批量导入 End -->
        <!-- 批量导入结果 Start -->
        <el-dialog
        title="成员批量导入结果"
        :visible.sync="importMemberCBK"
        width="600px"
        center>
        <div style="max-height:300px;overflow-y: auto;">
            <div class="marginAuto" v-for="(im,ix) in importCBK" :key="ix">
                {{im}}
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="importMemberCBK = false;">关 闭</el-button>
        </span>
        </el-dialog>
        <!-- 批量导入结果 End -->

        <el-dialog
            :visible.sync="dialogVisibleyaoqing"
            width="456px">
            <div class="inviteBox">
                <div class="inviteBoxTitle c33">
                    扫码快速加入机构
                </div>
                <img :src="inviteImg" alt="">
                <div class="downInviteBoxTitle flex alignCenter" @click="downImg">
                    <i class="el-icon-download" style="padding-right:8px;"></i>下载此二维码
                </div>
                <button class="closeInviteBox" @click="dialogVisibleyaoqing = false">关闭</button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { frame_list, add_next, new_next, organ_member_list, add_member, member_delall, new_branch } from '@/utils/Api/orgList'
import { scerweima } from '@/utils/Api/commonList'
import addMember from '@/components/addMember'
import uploadimg from '@/components/uploadimg'
export default {
    name:'orgChart',
    components:{addMember,uploadimg},
    data(){
        return{
            treeData:[
                {
                    name:'',
                    children:[],
                }
            ],  
            treeDefaultProps: {
                children: 'children',
                label: 'name'
            },
            centerDialogVisible: false,
            centerDialogTitle:'增加用户组',
            addOrg:{},
            showAddIndex: 0,
            treeAddIndex: 0,
            tempAddDate:{},             // 记录将要添加的部门父数据
            showAddBtn: true,
            rightShowList:[],
            editTurn: false,
            editTitle:'组织',
            new_next:{},                // 修改 机构、部门、科室、小组 信息（名称）变量,
            page: 1,
            showtableData:0,
            personListCount: 0,              // 人员表格--数量
            personListData:[],               // 人员表格 -- 数据
            addMember: false,
            addMemberObj:{},
            addMemberObj2:{},
            memberDel:[],
            handleTurn: false,
            flag: null,
            EditMember: false,              // 控制调整部门弹出显示
            importMember: false,            // 控制批量导入弹出显示
            importMemberCBK: false,         // 控制批量导入结果弹出显示
            importCBK:[],                   // 批量导入失败列表
            inviteImg:'',                   // 邀请二维码
            dialogVisibleyaoqing: false
        }
    },
    created(){
        this.treeData[0].name = this.user.name
        this.initial()
        this.organMemberList()
        // this.frameList({})
    },
    methods: {
        downImg(){
            window.open(this.inviteImg)
        },
        /* 邀请成员 */
        yaoqingchengyuan(){
            scerweima({
                url: `/pages/my/join_mechanism?name=${this.user.hospital.full_name}`,
                invite_code: this.user.code,
                organ_id: this.user.id
            }).then(res =>{
                this.inviteImg = res
                console.log(res)
            })
            this.dialogVisibleyaoqing=true
        },
        /* 批量导出成员结果 */
        getcallback(value){
            console.log(value)
            let msg = '导入结束，部分导入失败。'
            if(value.msg.length < 1){
                msg = "全部导入成功！"
                this.$message.success(msg)
                return
            }
            this.$confirm('导入结束，部分导入失败。', {
            confirmButtonText: '查看失败结果',
            cancelButtonText: '知道了',
            type: 'success'
            }).then(() => {
                this.importCBK = value.msg
                this.importMemberCBK = true
            }).catch(() => {
                       
            });
        },
        /* 成员单个删除 */
        delMember(im){
            this.$confirm('是否删除此用户', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.memberDel = [im.id]
                this.handleNext(1)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        /* 调整部门弹窗确认 */
        async editmemberNext(){
            let params = {
                user_ids: this.memberDel.join(','),
                ...this.addMemberObj2
            }
            let cbk = await new_branch(params)
            if(cbk.length < 1){
                this.$message.success('部门调整成功')
                this.organMemberList()
                this.EditMember = false
                this.handleTurn = false
                this.memberDel = []
            }
        },
        async handleNext(value){
            if(this.memberDel.length < 1){
                this.$message.warning('请选择要操作的用户！')
                return
            }
            if(this.flag == 1 || value == 1){
                let cbkDel = await member_delall({user_ids: this.memberDel.join(',')})
                if(cbkDel.length < 1){
                    this.memberDel = []
                    let showmsg = value ? '' : '批量'
                    this.$message.success(showmsg + '删除成功！')
                    this.organMemberList()
                }
                return
            }
            if(this.flag == 2){
                this.EditMember = true
            }
        },
        /* 批量删除 */
        handleDel(){
            this.handleTurn = true
            this.flag = 1
        },
        /* 调整部门 */
        handleBranch(){
            this.handleTurn = true
            this.flag = 2
        },
        /* 选中要删除的成员 */
        addMemberDel(im){
            let index = this.memberDel.indexOf(im.id)
            if(index == -1){
                this.memberDel.push(im.id)
            }else{
                this.memberDel.splice(index,1)
            }
        },
        /* 添加成员确定 */
        async addmemberNext(){
            let params = {
                ...this.addMemberObj,
                ...this.addMemberObj2
            }
            if(!params.name){
                this.$message.warning('请填写姓名！')
                return
            }
            if(!params.phone){
                this.$message.warning('请填写手机号！')
                return
            }
            if(!params.password){
                this.$message.warning('请填写密码！')
                return
            }
            if(!params.confirm_password){
                this.$message.warning('请再次填写密码！')
                return
            }
            if(params.password != params.confirm_password){
                this.$message.warning('两次密码填写不一致！')
                return
            }
            if(!(params.is_respon+'' == '0' || params.is_respon+'' == '1')){
                this.$message.warning('请选择是否设为部门负责人')
                return
            }
            let cbk = await add_member(params)
            if(cbk.length < 1){
                this.$message.success('人员添加成功！')
                this.organMemberList()
                this.addMember = false
            }
        },
        /* 接收子组件传递参数 */
        memberData(value){
            console.log(value)
            this.addMemberObj2 = value
        },
        /* 更改页码 */
        currentChange(value){
            this.page = value
            this.scrollTop()
            this.organMemberList()
        },
        /* 请求人员数据 */
        async organMemberList(value){
            let params = {
                type: 1,
                frame_id: null,
                page: this.page,
                limit: 5
            }
            if(value){
                try{
                    if(value.level){
                        params.type = value.level+1
                        params.frame_id = value.id
                    }
                }catch(e){console.log(e,'===========================--------------------------================')}
            }
            let cbk = await organ_member_list(params)
            this.personListCount = cbk.count
            this.showtableData = this.page - 1
            this.personListData[this.showtableData] = cbk.data
            // this.page = this.page+1

        },
        /* 确定修改 */
        async editNext(){
            let cbk = await new_next(this.new_next)
            if(cbk.length < 1){
                this.$message.success(`${this.editTitle}名称修改成功！`)
                this.editTurn = false
                this.new_next = {}
            }
        },
        /* 弹出修改 */
        editNode(im){
            console.log(im)
            console.log(im.level)
            switch (im.level){
                case 1:
                    this.editTitle = '组织'
                    break
                case 2:
                    this.editTitle = '部门'
                    break
                case 3:
                    this.editTitle = '科室'
                    break
                case 4:
                    this.editTitle = '小组'
                    break
            }
            this.new_next.next_id = im.id
            this.new_next.oldname = im.name
            this.editTurn = true
        },
        /* 右侧表格内容（部门 科室 等） */
        async frameList(value){
            let params = {}
            if(value.id){params.pid = value.id}
            let frlist = await frame_list(params)
            if(frlist){
                this.rightShowList = frlist
            }
        },
        /* 点击左侧节点 */
        nodeclick(data){			
            console.log(data)
            this.frameList(data)
            this.personListData = []
            this.personListCount = 0
            this.page = 1
            this.organMemberList(data)
        },
        /* 增加子节点 */
        async nextsetp(){
            if(!this.addOrg.name){
                this.$message.warning('请输入组织名称！')
                return
            }
            this.addOrg.level = this.showAddIndex
            try{
                let cbk = await add_next(this.addOrg)
                console.log(cbk)
                if(cbk.length < 1){
                    switch (this.showAddIndex){
                        case 1:
                            this.$message.success('用户组增加成功！')
                            break
                        case 2:
                            this.$message.success(`${this.tempAddDate.name}组织增加部门成功！`)
                            break
                        case 3:
                            this.$message.success(`${this.tempAddDate.name}部门增加科室成功！`)
                            break
                        case 4:
                            this.$message.success(`${this.tempAddDate.name}科室增加小组成功！`)
                            break
                    }
                    this.centerDialogVisible = false
                    this.initial()
                }
            }catch(e){console.log(e)}
        },
        /* 查询机构下所有的医院 */
        async initial(){
            let cbk = await frame_list()
            this.treeData[0].name = this.user.name + `(${cbk.length})`
            this.$forceUpdate()
        },
        /* tree组件懒加载所有数据 */
        async loadNode(node, resolve){
            console.log(node)
            this.tempAddDate = node.data
            try{
                if(node.data.level == 4 || node.data.level == 5){
                    this.showAddBtn = false
                }else{
                    this.showAddBtn = true
                }
            }catch(e){console.log(e)}
            try{
            if(node.level === 0){
                return resolve([this.treeData[0]])
            }
            if(node.level === 1){
                let level1 = await frame_list()
                console.log(level1)
                level1.forEach(im => {
                    im.name = im.name + `（${im.count}）`
                });
                return resolve(level1)
            }
            if(node.level === 2){
                let level2 = await frame_list({pid: node.data.id})
                level2.forEach(im => {
                    im.name = im.name + `（${im.count}）`
                });
                return resolve(level2)
            }
            if(node.level === 3){
                let level3 = await frame_list({pid: node.data.id})
                level3.forEach(im => {
                    im.name = im.name + `（${im.count}）`
                });
                return resolve(level3)
            }
            if(node.level === 4){
                let level4 = await frame_list({pid: node.data.id})
                level4.forEach(im => {
                    im.name = im.name + `（${im.count}）`
                });
                return resolve(level4)
            }
            if(node.level === 5){
                return resolve([])
            }
            }catch(e){console.log(e,'========================================403')}
        },
        /* 增加子节点弹出 */
        addChildren(){
            this.showAddIndex = 1
            this.centerDialogVisible = true
        },
        /* 添加子部门 */
        addChildrenBranch(){
            console.log(this.tempAddDate)
            this.centerDialogTitle = `添加  ${this.tempAddDate.name}   的部门`
            this.showAddIndex = this.tempAddDate.level + 1
            this.addOrg.pid = this.tempAddDate.id
            this.addOrg.level = this.tempAddDate.level + 1
            this.centerDialogVisible = true
        },
    },
    computed:{
        user(){
            return this.$store.getters.personal
        }
    },
    watch:{
        rightShowList(){
            console.log('-----------------------------------------------------------------------')
            console.log(this.rightShowList)
        },
        memberDel(){
            console.log(this.memberDel)
        }
    },
    filters:{
        filt(val){
            console.log(val)
            if(val){
                return val.split('（')[0]
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.upcss{
    height: 130px;
    width: 100px;
    .borders{
        width: 100px;
        height: 100px;
        border: 1px solid #dddddd;
    }
}
.isCurr{
    background: #47D7E3;
    border: #47D7E3;
}
.nocurr{
    width: 14px;
    height: 14px;
    border: 1px solid #333;
    font-size: 14px;
    color: #fff;
}
.tablePersonal{
    width: 100%;
    max-height: 460px;
    text-align: center;
    border: 1px solid #e8e8e8;
    margin-top: 20px;
    th{
        border-bottom: 1px solid #e8e8e8;
    }
    td{
        border: 1px solid #e8e8e8;
    }
    span{
        font-size: 15px;
        color: #FF4141;
    }
    tr{
        height: 82px;
    }
}
.scrollVessel{
    margin-left: 30px;
    margin-top: 20px;
    height: 200px;
    width: 620px;
    overflow-y: auto;
    .vesselList{
        height: 65px;
        border-bottom: 1px dashed #d8d8d8;
        font-size: 15px;
        padding-left: 10px;
        span{
            color: #0B60FF;
            cursor: pointer;
            font-weight: normal;
            padding-right: 10px;
        }
    }
}
.tables{
    margin-left: 20px;
    margin-top: 20px;
    .tablesLeft{
        width: 224px;
        height: 608px;
        border: 1px solid #d6d6d6;
        overflow-x: auto;
    }
    .tablesright{
        width: 660px;
        margin-left: 16px;
        .tablesright2{
            width: 100%;
            margin-top: 25px;
            .tablesright1Title{
                font-size: 15px;
                color: #505050;
                margin-top: 27px;
                margin-left: 30px;
            }
        }
        .tablesright1{
            width: 100%;
            min-height: 300px;
            border: 1px solid #d6d6d6;
            text-align: left;
            .tablesright1Title{
                font-size: 15px;
                color: #505050;
                margin-top: 27px;
                margin-left: 30px;
            }
        }
    }
}
button{
    font-size: 16px;
    padding: 12px 16px;
    background: #47D7E3;
    color: #fff;
    cursor: pointer;
}
.orgChart{
    .opers{
        text-align: left;
        margin-left: 20px;
        margin-top: 10px;
    }
}


.scrollVessel::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}
.scrollVessel::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    background   : #535353;
}
.scrollVessel::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background   : #ededed;
}
.inviteBox{
    width: 456px;
    height: 350px;
    overflow: hidden;
    margin-left: -20px;
    .closeInviteBox{
        width: 79px;
        height: 33px;
        opacity: 1;
        background: #47d7e3;
        border-radius: 2px;
        color: #FFFFFF;
        font-size: 15px;
        margin-top: 15px;
        cursor: pointer;
        line-height: 13px;
    }
    .downInviteBoxTitle{
        text-align: center;
        font-size: 13px;
        color: #47D7E3;
        width: 100px;
        margin: auto;
        margin-top: 11px;
        cursor: pointer;
    }
    .inviteBoxTitle{
        font-size: 20px;
        margin-top: 36px;
    }
    img{
        margin: auto;
        margin-top: 25px;
        width: 158px;
        height: 158px;
    }
}
</style>